import React from 'react';
import styled from 'styled-components';
import {Popconfirm, Button, message} from 'antd';
import {useOutlet} from 'reconnect.js';
import {BlobProvider} from '@react-pdf/renderer';
import * as Admin from 'rev.sdk.js/Actions/Admin';
import * as Cart from 'rev.sdk.js/Actions/Cart';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import LogisticsTrackingButton from '../../../Components/LogisticsTrackingButton';
import OrderPdf from './OrderPdf';
import LogisticsSfModal from './LogisticsSfModal';
import LogisticsSfOverseaModal from './LogisticsSfOverseaModal';
import ShippingNoteDownloadPdfButton from './ShippingNoteDownloadPdfButton';

function ActionButtonSection(props) {
  const [actions] = useOutlet('actions');
  const {
    context: {instance, values, setValues},
  } = props;

  const [showLogisticsSfModal, setShowLogisticsSfModal] = React.useState(false);
  const [
    showOverseaLogisticsSfModal,
    setShowOverseaLogisticsSfModal,
  ] = React.useState(false);

  const handleGenLogistics = React.useCallback(async () => {
    try {
      actions.setLoading(true);

      await Admin.createLogistics(values.id);
      message.success('建立成功');
    } catch (e) {
      console.warn(e);
      message.error('發生錯誤');
    } finally {
      actions.setLoading(false);
    }
  }, [actions, values.id]);

  return (
    <Wrapper>
      <div className="title">動作</div>
      <div className="container">
        {values.payment_subtype === Cart.PAYMENT_SUBTYPE.offline && (
          <Popconfirm
            disabled={values.payment_status === Cart.PAYMENT_STATUS.success}
            title="已經確認匯款後五碼？"
            okText="確認"
            cancelText="取消"
            onConfirm={async () => {
              try {
                const _hide = message.loading('更新金流狀態...');
                await Admin.confirmOfflineOrder(instance.id);
                const order = await JStorage.fetchOneDocument('order', {
                  id: instance.id,
                });
                setValues(order);
                _hide();
              } catch (err) {
                console.warn(err);
                message.error('切換金流狀態失敗。');
              }
            }}>
            <Button
              type="primary"
              ghost
              disabled={values.payment_status === Cart.PAYMENT_STATUS.success}>
              切換金流狀態為成功
            </Button>
          </Popconfirm>
        )}

        {instance.order_id && (
          <Button
            type="primary"
            ghost
            disabled={instance.invoice_number}
            onClick={async () => {
              try {
                const _hide = message.loading('更新發票狀態...');
                await Admin.createInvoice(
                  instance.order_id,
                  `開立時間：${new Date().toLocaleString()}`,
                );
                const order = await JStorage.fetchOneDocument('order', {
                  id: instance.id,
                });
                setValues(order);
                _hide();
              } catch (err) {
                console.warn(err);
                message.error('開立發票失敗。');
              }
            }}>
            開立發票
          </Button>
        )}

        {instance.logistics_subtype === 'SF' ? (
          <>
            <Button
              type="primary"
              ghost
              disabled={instance.logistics_id}
              onClick={() => setShowLogisticsSfModal(true)}>
              建立順豐物流單
            </Button>
            <LogisticsSfModal
              visible={showLogisticsSfModal}
              instance={instance}
              onClose={() => setShowLogisticsSfModal(false)}
            />
          </>
        ) : instance.logistics_type === 'OVERSEA' ? (
          <>
            <Button
              type="primary"
              ghost
              disabled={instance.logistics_id}
              onClick={() => setShowOverseaLogisticsSfModal(true)}>
              建立海外物流單
            </Button>
            <LogisticsSfOverseaModal
              visible={showOverseaLogisticsSfModal}
              instance={instance}
              onClose={() => setShowOverseaLogisticsSfModal(false)}
            />
          </>
        ) : !instance.is_custom ? (
          <Button
            type="primary"
            ghost
            disabled={
              !['created', 'pending', 'error', 'exception', undefined].includes(
                values?.logistics_status,
              )
            }
            onClick={handleGenLogistics}>
            建立物流單
          </Button>
        ) : null}

        <LogisticsTrackingButton order={instance} />

        {!instance.is_custom &&
          instance.logistics_subtype !== 'SF' &&
          instance.logistics_type !== 'OVERSEA' && (
            <>
              <BlobProvider document={<OrderPdf order={instance} />}>
                {({...rest}) => {
                  if (rest.url) {
                    return (
                      <Button
                        type="primary"
                        ghost
                        onClick={() => {
                          window.open(rest.url, '_blank');
                        }}>
                        下載揀貨單
                      </Button>
                    );
                  } else {
                    return 'loading...';
                  }
                }}
              </BlobProvider>

              <ShippingNoteDownloadPdfButton order={instance} />
            </>
          )}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;

  display: flex;
  align-items: center;
  flex-wrap: wrap;

  & > .title {
    margin-right: 10px;
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--primaryColor);
  }

  & > .container {
    display: flex;
    flex-wrap: wrap;

    & button {
      margin: 8px;
    }
  }
`;

export default ActionButtonSection;
