import React from 'react';
import {getOutlet} from 'reconnect.js';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';

const ProductsOutlet = getOutlet('products');
const ArticlesOutlet = getOutlet('articles');

export default function useAdminResourceInit({location}) {
  React.useEffect(() => {
    // trigget only once
    async function fetchAllProducts() {
      try {
        let resp = await JStorage.fetchAllDocuments('product', {}, null, {
          name: 1,
          price: 1,
        });
        ProductsOutlet.update(resp);
      } catch (err) {
        console.warn(err);
      }
    }

    async function fetchAllArticles() {
      try {
        let resp = await JStorage.fetchAllDocuments(
          'Article_Default',
          {},
          null,
          {
            title: 1,
          },
        );

        ArticlesOutlet.update(resp);
      } catch (err) {
        console.warn(err);
      }
    }

    fetchAllProducts();
    fetchAllArticles();
  }, []);

  React.useEffect(() => {
    // trigger when location change
  }, [location]);

  return {
    extraElement: null,
  };
}
