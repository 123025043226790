import React from 'react';
import styled from 'styled-components';
import * as AppActions from '../../AppActions';
import RichTextEditor from 'rev.sdk.js/Components/RichTextEditor';
import {
  generateHtml,
  upsertArticle,
} from 'rev.sdk.js/Components/ArticleEditor/ArticleEditorUtil';
import * as Ant from 'antd';

const MULTILINGUAL_FIELDS = {
  content: 'tw',
  content_en: 'en',
  content_jp: 'jp',
};

export default function ArticleBelowSection({
  name,
  type,
  context,
  path,
  multilingual = false,
}) {
  const {position, instance, values, setValues, errors, formSpec} = context;
  const {schema, uiSchema, errorSchema} = formSpec;
  const [contents, setContents] = React.useState({
    content: undefined,
    content_en: undefined,
    content_jp: undefined,
  });
  const [tab, setTab] = React.useState('content'); // available if multilingual

  React.useEffect(() => {
    if (instance) {
      setContents((prev) => ({...prev, content: instance['content']}));
      if (multilingual) {
        setContents((prev) => ({
          ...prev,
          content_en: instance['content_en'],
          content_jp: instance['content_jp'],
        }));
      }
    }
  }, [instance]);

  const submit = async () => {
    // get article content & html

    const formData = {
      ...values,
      content: contents.content,
      html: await generateHtml(contents.content),
      ...(multilingual && {
        content_en: contents.content_en,
        html_en: await generateHtml(contents.content_en),
        content_jp: contents.content_jp,
        html_jp: await generateHtml(contents.content_jp),
      }),
    };

    await upsertArticle(formData, instance, {
      onUpdate: (createdDoc) =>
        AppActions.navigate(path + `?action=detail&id=${createdDoc.id}`),
    });
  };

  return (
    <Wrapper>
      <h2>文章內容</h2>
      {multilingual &&
        Object.keys(MULTILINGUAL_FIELDS).map((key) => (
          <Ant.Button
            type={tab === key ? 'primary' : 'default'}
            onClick={() => setTab(key)}>
            {MULTILINGUAL_FIELDS[key]}
          </Ant.Button>
        ))}
      <div className="content">
        {multilingual && tab === 'content_jp' ? (
          '尚未開放'
        ) : multilingual ? (
          <RichTextEditor
            options={{}}
            token=""
            key={tab}
            onChange={(value) =>
              setContents((prev) => ({...prev, [tab]: value}))
            }
            content={contents[tab]}
          />
        ) : (
          <RichTextEditor
            options={{}}
            token=""
            onChange={(value) =>
              setContents((prev) => ({...prev, content: value}))
            }
            content={contents.content}
          />
        )}
      </div>
      <div className="footer">
        {instance && (
          <div>
            建立：{new Date(instance.created).toLocaleString('sv')}
            {' / '}
          </div>
        )}
        {instance && (
          <div>
            更新：{new Date(instance.updated).toLocaleString('sv')}
            {' / '}
          </div>
        )}
        {instance && <div>{instance.id}</div>}

        <div style={{flex: 1}} />
        <Ant.Button
          type="primary"
          onClick={() => {
            submit();
          }}>
          {instance ? '編輯' : '新增'}
        </Ant.Button>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 25px;
  margin-bottom: 30px;
  border-top: 1px solid var(--primaryColor);

  & > h2 {
    margin: 15px 0;
  }

  & .content {
    padding: 8px;
    background-color: #fff;
    margin-bottom: 30px;
  }

  & > .footer {
    display: flex;
    align-items: center;

    & > div {
      color: #aaa;
      font-size: 12px;
      margin-right: 5px;
    }
  }
`;
