import React, {useCallback} from 'react';
import {DatePicker} from 'antd';
import moment from 'moment';

function RoomDateFilter(props) {
  const {setQueryState, queryState} = props;

  const onChange = useCallback((dateString) => {
    if (dateString) {
      setQueryState((prev) => ({
        ...prev,
        extraQueries: {
          ...prev.extraQueries,
          'items.config.from_date': dateString,
        },
      }));
    } else {
      setQueryState((prev) => ({
        ...prev,
        extraQueries: {
          ...prev.extraQueries,
          'items.config.from_date': undefined,
        },
      }));
    }
  }, []);

  return (
    <div>
      <div>入住日期</div>
      <DatePicker
        placeholder="選擇日期"
        defaultValue={moment()}
        onChange={(date, dateString) => onChange(date ? dateString : undefined)}
      />
    </div>
  );
}

export {RoomDateFilter};
