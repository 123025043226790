import React from 'react';
import {Button} from 'antd';
import {getOutlet} from 'reconnect.js';
import * as AsyncStorage from 'rev.sdk.js/Utils/AsyncStorage';
import * as AppActions from '../../AppActions';

export function handleRoomOrder({path, pageContext}) {
  if (path.indexOf('/admin/room_order') > -1) {
    pageContext.resource.renderCreateButton = () => {
      return (
        <Button
          type="primary"
          onClick={async (e) => {
            AppActions.setLoading(true);
            AppActions.delay(500);
            await AsyncStorage.removeItem('token');
            await AsyncStorage.setItem(
              'admin-access-token',
              getOutlet('user').getValue().token,
            );
            AppActions.setLoading(false);
            AppActions.delay(100);
            window.open('/booking-internal/', '_blank');
          }}>
          新訂房
        </Button>
      );
    };
  }
}
