import React from 'react';
import constants from '../../constants';
import AdminLinkButton from './AdminLinkButton';

export default function AdminSiteConfigButton(props) {
  const {record} = props;
  return (
    <AdminLinkButton
      title={constants.SITE_CONFIG[record.name]?.display || record.name}
      url={`/admin/${record.name}?action=detail&id=${record.id}`}
    />
  );
}
