import React from 'react';
import AdminLinkButton from '../../Generators/AdminResource/AdminLinkButton';
import AdminRoomCheckoutDetailForm from '../../Generators/AdminResource/AdminRoomCheckoutDetailForm';
import {Link} from 'gatsby';

export function renderCustomAdminSection(props) {
  const {name, type, context} = props;

  if (
    type === 'form' &&
    name === 'AdminRoomCheckoutDetailForm' &&
    context.position === 'bottom'
  ) {
    return [true, <AdminRoomCheckoutDetailForm context={context} />];
  }

  return [false, null];
}

export function renderCustomAdminCol(props) {
  const {col, record} = props;

  if (col.customType === 'order_status') {
    return [
      true,
      <div>
        <div>
          {
            {
              waiting: '待處理',
              processing: '處理中',
              done: '已完成',
              canceled: '已取消',
              return_applied: '退貨審核通過',
              return_completed: '已退款',
            }[record.status]
          }
        </div>
      </div>,
    ];
  }

  if (col.customType === 'room_checkout') {
    return [
      true,
      <div>
        {/* <Link to={`/admin/room_order/?action=detail&id=${record.order}`}>
            {record.room_order?.order_id || '查看訂單'}
          </Link> */}
        <div>{record.staff_note}</div>
        <div style={{color: '#909090', fontSize: 12}}>
          {record.payment_transaction_id}
        </div>
        <div>
          {record.items[0].config.from_date} ~ {record.items[0].config.to_date}
        </div>
        {record.items.map((item, idx) => (
          <div key={idx}>
            <div>
              {idx + 1}. {item.name}
            </div>
          </div>
        ))}
      </div>,
    ];
  }

  return [false, null];
}
