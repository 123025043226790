import React from 'react';
import {Button, message, Popconfirm} from 'antd';
import * as Jstorage from 'rev.sdk.js/Actions/JStorage';
import * as CatUtil from '../../Utils/CatUtil';
import * as AppActions from '../../AppActions';
import AdminLinkButton from './AdminLinkButton';

export function handleProducts({path, pageContext}) {
  if (path.indexOf('/admin/archived_products') > -1) {
    const firstCol = pageContext.resource.columns[0];
    pageContext.resource.columns[0] = {
      ...firstCol,
      render: (text, record) => {
        const url = `/admin/products/?action=detail&id=${record.id}`;
        return <AdminLinkButton url={url} title={record.name} />;
      },
    };
  }

  if (path.indexOf('/admin/products') > -1) {
    const firstCol = pageContext.resource.columns[0];
    pageContext.resource.columns[0] = {
      ...firstCol,
      render: (text, record) => {
        const url = `/admin/products/?action=detail&id=${record.id}`;

        return (
          <AdminLinkButton
            url={url}
            title={`${record.name}${
              record.session && Object.keys(record.session).length > 0
                ? ` ${record.session.date} ${record.session.start_time}-${record.session.end_time}`
                : ``
            }`}
          />
        );
      },
    };

    const secondCol = pageContext.resource.columns[1];
    pageContext.resource.columns[1] = {
      ...secondCol,
      render: (text, record) => {
        return (
          <div>
            {
              pageContext.resource.formSpec.schema.properties.type.enumNames[
                pageContext.resource.formSpec.schema.properties.type.enum.findIndex(
                  (ele) => ele === record.type,
                ) || 0
              ]
            }
          </div>
        );
      },
    };

    const cats = CatUtil.getFlattenCategories('categories');
    if (pageContext.resource.formSpec.schema?.properties?.labels?.items) {
      pageContext.resource.formSpec.schema.properties.labels.items = {
        type: 'string',
        enum: cats.map((cat) => cat.name),
        enumNames: cats.map((cat) => cat.display),
      };
    }

    pageContext.resource.renderDetailButton = (record, clientCallbacks) => {
      return (
        <div style={{display: 'flex'}}>
          <Popconfirm
            title={`確定複製「${record.name}」商品嗎？`}
            onConfirm={async () => {
              AppActions.setLoading(true);
              try {
                let data = {...record};

                delete data.id;
                delete data._id;

                await Jstorage.createDocument('product', {
                  ...data,
                  name: `${data.name}*`,
                  public: false,
                  article: '',
                });
                await clientCallbacks.refresh();
              } catch (err) {
                message.err('發生錯誤。');
              }
              AppActions.setLoading(false);
            }}>
            <Button type="link">複製</Button>
          </Popconfirm>
          <AdminLinkButton
            title="編輯庫存"
            url={
              '/admin/product_stock?productId=' + record.id
            }></AdminLinkButton>
        </div>
      );
    };
  }
}
