import React from 'react';
import {Collapse} from 'antd';
import OrderField from 'rev.sdk.js/Components/OrderField';
import CartList from 'rev.sdk.js/Components/CartList';

const {Panel} = Collapse;

export default function AdminRoomCheckoutDetailForm(props) {
  const {context} = props;
  const {instance} = context;

  const {from_date, to_date} = React.useMemo(() => {
    let from_date = '',
      to_date = '';
    if (instance) {
      from_date = instance.items[0].config.from_date;
      to_date = instance.items[0].config.to_date;
    }
    return {from_date, to_date};
  }, [instance]);

  return (
    <div style={{margin: '5px 0'}}>
      <Collapse defaultActiveKey={[0, 1, 2]}>
        <Panel header="訂單內容" key={0}>
          <CartList cartItems={instance.items} disabled={true} />
        </Panel>
        <Panel header="訂房資訊" key={1}>
          <OrderField name="成人數量" value={instance.adults} />
          <OrderField name="小孩數量" value={instance.kids} />
          <OrderField name="入住日期" value={from_date} />
          <OrderField name="退房日期" value={to_date} />
          <OrderField name="預期到達時間" value={instance.arrival_time} />
        </Panel>
        <Panel header="購買人資訊" key={2}>
          <OrderField name="姓名" value={instance.buyer_name} />
          <OrderField name="行動電話" value={instance.buyer_phone} />
          <OrderField name="電子信箱" value={instance.buyer_email} />
        </Panel>
      </Collapse>
    </div>
  );
}
